import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CategoryCard from "../components/CategoryCard.js";
// import WinnersPopup from "../components/WinnersPopup.js";
import ArPopup from "../components/ArPopup.js";

// const winnerList = [
//   { region: "Andalucia", winner: "José Angel Gómez" },
//   { region: "Centro", winner: "Antonio López" },
//   { region: "Levante", winner: "Minerva González" },
//   { region: "Nordeste", winner: "Hugo Pérez" },
//   { region: "Norte", winner: "Ramiro Moreno" },
// ];

const Home = () => {
  const [categories, setCategories] = useState([]);
  // const [winnersPopup, showWinnersPopup] = useState(false);

  const [arPopup, showArPopup] = useState(
    window.localStorage.getItem("arpopup") !== "true",
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getCategories();
    // if (location.state && location.state.emailToken) {
    //   showWinnersPopup(true);
    // }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };

  const getCategories = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/elements`, {
      method: "GET",
      headers: headers,
    })
      .then(async (res) => {
        const data = await res.json();
        if (res.status === 401) navigate("/login");
        if (data.status === 200) {
          setCategories(data.body.sort((a, b) => (a.order > b.order ? 1 : -1)));
        } else navigate("/login");
      })
      .catch((error) => {
        console.error(error);
        navigate("/login");
      });
  };

  return (
    <div className="cards-page">
      <h1 className="header">Modelos</h1>
      <div className="cards-container">
        {categories.map((c, i) => {
          return (
            <CategoryCard
              key={c.id}
              id={c.id}
              name={c.name}
              count={c.count}
              image={c.picture}
            />
          );
        })}
      </div>
      {/*<WinnersPopup
        show={winnersPopup}
        className="winners-popup"
        winners={winnerList}
        close={() => showWinnersPopup(false)}
      />*/}
      <ArPopup
        show={arPopup}
        className="ar-popup"
        close={() => showArPopup(false)}
      />
    </div>
  );
};

export default Home;
